import { AppProps } from 'next/app'
import { useEffect, useRef } from 'react'
import { Provider, teamsTheme } from '@fluentui/react-northstar'
import { SessionProvider } from 'next-auth/react'
import '../styles/globals.css'
import 'primereact/resources/themes/fluent-light/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'styles/customizedPrimeTheme.scss'
import Head from 'next/head'
import { useRouter } from 'next/router'
import '@/utils/domPurifyHooks'
import * as Analytics from 'summer-analytics-client'
import { APP_NAME, VERSION } from '@/constants'

const PATHS_WITHOUT_SESSION_PROVIDER = ['/teams_logout', '/teams_callback']

const useEventTracking = (email: string | undefined) => {
  const firstRun = useRef(true)
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    if (firstRun.current) {
      Analytics.init({
        user: email,
        app_name: APP_NAME,
        app_version: VERSION,
      })
      firstRun.current = false
    } else {
      Analytics.setUserId(email)
    }
  }, [email])
}

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const router = useRouter()
  const email = session?.user?.email
  useEventTracking(email)

  const content = (
    <Provider theme={teamsTheme}>
      <Head>
        <title>Delayed Send</title>
        <meta name="description" content="Schedule Microsoft Teams Messages" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/logo.png" />
      </Head>
      <Component {...pageProps} />
    </Provider>
  )

  // SessionProvider disabled in Teams popups because of session not being deleted while signing out
  return PATHS_WITHOUT_SESSION_PROVIDER.some((path) => router.asPath.includes(path)) ? (
    content
  ) : (
    <SessionProvider session={session}>{content}</SessionProvider>
  )
}

export default MyApp
