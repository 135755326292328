import { EventType, AppEvent, IAppEvent } from './events'

export * from './events'

type SDK = typeof import('@amplitude/analytics-browser')
const AMPLITUDE_ID = 'b15b61a987e062b6472790dce4f59d05'

interface IInitParams {
  user?: string
  app_name: string
  app_version: string
}

let sdkPromise: Promise<SDK> | undefined = undefined

export const init = ({ user, app_name, app_version }: IInitParams) => {
  sdkPromise = import('@amplitude/analytics-browser')
    .then((sdk) => {
      const identifyObj = new sdk.Identify()
      identifyObj.set('app_name', app_name)
      identifyObj.set('app_version', app_version)
      sdk.identify(identifyObj)
      sdk.init(AMPLITUDE_ID, user)
      return sdk
    })
    .then((sdk) => {
      trackEvent({
        type: EventType.AppStarted,
        params: {
          location: window.location.href,
        },
      })
      return sdk
    })
}

export const trackEvent = (event: AppEvent) => {
  const evt = event as IAppEvent
  sdkPromise?.then((sdk) => {
    sdk.track(evt.type, evt.params)
    return sdk
  })
}

export const setUserId = (email: string | undefined) => {
  sdkPromise?.then((sdk) => {
    sdk.setUserId(email)
    return sdk
  })
}
